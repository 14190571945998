const $ = window.jQuery
const Swiper = window.Swiper
const Splitting = window.Splitting

function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
}

export const onRouteUpdate = ({ location }) => {
  const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  setTimeout(() => {
    window.gtag?.('event', 'page_view', { page_path: pagePath });
  }, 100);

  const preload = $('.preloader');
  const body = $('body');
  const header = $('.header');

  if (preload) {
    preload.addClass('loaded');
    preload.find('.centrize').fadeOut();
  }
  $('.scroll-animate').scrolla({
    once: true,
    mobile: true
  });

  $(function() {
    /**
     Sections full height
     **/
    setHeightFullSection();
    $(window).resize(function() {
      setHeightFullSection();
    });

    /**
     Parallax
     **/
    $('.js-parallax').jarallax({
      speed: 0.65,
      type: 'scroll'
    });

    /**
     Block Line
     **/
    if ($('.v-line').length) {
      $('.v-line .container').append('<div class="v-line-block"><span></span></div>');
      $('.v-line .hero-started').append('<div class="v-line-block"><span></span></div>');
    }

    /**
     Splitting
     **/
    Splitting();

    /**
     Header Sticky
     **/
    let lastScroll = 0;
    if(header.length) {
      $(window).on('scroll', function(event){
        let currentScroll = window.pageYOffset;

        if (currentScroll - lastScroll > 0) {
          // scrolling down
          header.removeClass('animate-in');
          header.addClass('animate-out');
        } else {
          // scrolled up -- header show
          if ( $(window).scrollTop() > 100 ) {
            header.addClass('sticky');
            header.removeClass('animate-out');
            header.addClass('animate-in');
          } else {
            header.removeClass('sticky');
            header.removeClass('animate-in');
            header.removeClass('animate-out');
          }
        }
        lastScroll = currentScroll;
      });
    }

    const loveAnim = $(".love")
    if (loveAnim.attr('listener') !== 'true') {
      loveAnim.on("mouseover", function() {
        var b = Math.floor((Math.random() * 100) + 1);
        var d = ["flowOne", "flowTwo", "flowThree"];
        var a = ["colOne", "colTwo", "colThree", "colFour", "colFive", "colSix"];
        var c = (Math.random() * (1.6 - 1.2) + 1.2).toFixed(1);
        $('<div class="heart part-' + b + " " + a[Math.floor((Math.random() * 6))] + '" style="font-size:' + Math.floor(Math.random() * (50 - 22) + 22) + 'px;"><i class="fa fa-heart"></i></div>').appendTo(".hearts").css({
          animation: "" + d[Math.floor((Math.random() * 3))] + " " + c + "s linear"
        });
        $(".part-" + b).show();
        setTimeout(function() {
          $(".part-" + b).remove()
        }, c * 900)
      });
    }
    loveAnim.attr('listener', 'true');

    const the_animation = document.querySelectorAll('.btn--shockwave')

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('is-active');
                setTimeout(() => {
                  entry.target.classList.remove('is-active')
                }, 2600)
            }  
        })
    },{ threshold: 0.8});
    for (let i = 0; i < the_animation.length; i++) {
      const elements = the_animation[i];
      observer.observe(elements);
    } 

    /**
     * Switcher audio files
     */
    const audioOn = document.createElement('audio');
    audioOn.setAttribute('src', '/audio/switch-on.mp3');
    audioOn.addEventListener('ended', function() {
      audioOn.currentTime = 0;
    }, false);
    const audioOff = document.createElement('audio');
    audioOff.setAttribute('src', '/audio/switch-off.mp3');
    audioOff.addEventListener('ended', function() {
      audioOff.currentTime = 0;
    }, false);

    /**
     Header Switcher Button
     **/
    const skin = getCookie('skin');
    if ( skin === 'light' ) {
      body.removeClass('dark-skin');
      body.addClass('light-skin');
    }
    if ( skin === 'dark' ) {
      body.removeClass('light-skin');
      body.addClass('dark-skin');
    }

    const menuBtn = $('.menu-btn');
    const menuFullOverlay = $('.menu-full-overlay');
    const menuOverlay = $('.menu-overlay')
    const openMenu = () => {
      menuBtn.addClass('active no-touch');
      menuOverlay.addClass('no-touch');
      const height = $(window).height();
      menuFullOverlay.css({'height': height});
      $('body').addClass('no-scroll');
      menuFullOverlay.addClass('is-open visible');
      setTimeout(function(){
        menuFullOverlay.addClass('has-scroll animate-active');
        menuBtn.removeClass('no-touch');
        menuOverlay.removeClass('no-touch');
      }, 1000);
    }

    const closeMenu = () => {
      menuBtn.removeClass('active');
      menuBtn.addClass('no-touch');
      menuOverlay.addClass('no-touch');
      $('body').removeClass('no-scroll');
      menuFullOverlay.removeClass('is-open');
      menuFullOverlay.removeClass('has-scroll');
      menuFullOverlay.removeClass('animate-active');
      setTimeout(function(){
        menuFullOverlay.removeClass('visible');
        menuBtn.removeClass('no-touch');
        menuOverlay.removeClass('no-touch');
      }, 1000);
    }
    /**
     Close on route change
     **/
    closeMenu();
    if (header.attr('listener') !== 'true') {
      header.on('click', '.theme-toggle', function(){
        if ( body.hasClass('dark-skin') ) {
          body.removeClass('dark-skin');
          body.addClass('light-skin');
          setCookie('skin', 'light', 7);
          audioOn.play();
        }
        else {
          body.removeClass('light-skin');
          body.addClass('dark-skin');
          setCookie('skin', 'dark', 7);
          audioOff.play();
        }
        return false;
      });

      /**
       Header Menu Button
       **/
      header.on('click', '.menu-btn', function(){
        if($(this).hasClass('active')) {
          closeMenu();
        }
        else {
          openMenu();
        }
        return false;
      });
      menuFullOverlay.on('click', '.menu-overlay', function(){
        menuBtn.removeClass('active');
        menuBtn.addClass('no-touch');
        menuOverlay.addClass('no-touch');
        body.removeClass('no-scroll');
        menuFullOverlay.removeClass('is-open');
        menuFullOverlay.removeClass('has-scroll');
        menuFullOverlay.removeClass('animate-active');
        setTimeout(function(){
          menuFullOverlay.removeClass('visible');
          menuBtn.removeClass('no-touch');
          menuOverlay.removeClass('no-touch');
        }, 1000);
        return false;
      });
    }
    $('body').removeClass('no-scroll');
    header.attr('listener', 'true');

    /*
      Carousel Services
    */
    const jsServices = $('.js-services');
    if (jsServices.attr('listener') !== 'true') {
      const servicesSwiper = new Swiper('.js-services', {
        slidesPerView: 3,
        spaceBetween: 40,
        watchSlidesVisibility: true,
        noSwipingSelector: 'a',
        loop: false,
        speed: 1000,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        navigation: false,
        breakpoints: {
          // when window width is >= 320px
          0: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // when window width is >= 480px
          767: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          // when window width is >= 640px
          1024: {
            slidesPerView: 3,
            spaceBetween: 40
          }
        }
      });
      servicesSwiper.on('slideChange', function () {
        window.gtag?.('event', 'swiper_slide', {
          'event_label': 'Swiper Slide',
          'event_category': 'swiper_slide',
          'swiper': 'day_to_day'
        });
      });
    }
    jsServices.attr('listener', 'true');

    /*
      Carousel Testimonials
    */
    const jsTestimonials = $('.js-testimonials');
    if (jsTestimonials.attr('listener') !== 'true') {
      const testimonialsSwiper = new Swiper('.js-testimonials', {
        slidesPerView: 3,
        spaceBetween: 40,
        watchSlidesVisibility: true,
        noSwipingSelector: 'a',
        loop: false,
        speed: 1000,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        navigation: false,
        breakpoints: {
          // when window width is >= 320px
          0: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // when window width is >= 480px
          767: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          // when window width is >= 640px
          1024: {
            slidesPerView: 3,
            spaceBetween: 40
          }
        }
      });
      testimonialsSwiper.on('slideChange', function () {
        window.gtag?.('event', 'swiper_slide', {
          'event_label': 'Swiper Slide',
          'event_category': 'swiper_slide',
          'swiper': 'testimonials'
        });
      });
    }
    jsTestimonials.attr('listener', 'true');
    /*
      Initialize portfolio items
    */
    var $container = $('.works-items');
    $container.imagesLoaded(function() {
      $container.isotope({
        itemSelector: '.works-col',
        percentPosition: true,
      });
    });

    var $gal_container = $('.m-gallery');
    $gal_container.imagesLoaded(function() {
      $gal_container.isotope({
        itemSelector: '.col-lg-6',
        percentPosition: true,
      });
    });

    /*
      Filter items on button click
    */
    $('.filter-links').on( 'click', 'a', function() {
      var filterValue = $(this).attr('data-href');
      $container.isotope({ filter: filterValue });

      $('.filter-links a').removeClass('active');
      $(this).addClass('active');

      if (!$(filterValue).find('.scroll-animate').hasClass('animate__active')) {
        $(filterValue).find('.scroll-animate').addClass('animate__active');
      }

      return false;
    });


    /**
     Collapse
     **/
    const collapse = $('.lui-collapse-item');
    if (collapse.attr('listener') !== 'true') {
      collapse.on('click', '.lui-collapse-btn', function(){
        if($(this).closest('.lui-collapse-item').hasClass('opened')) {
          $(this).closest('.lui-collapse-item').removeClass('opened');
          $(this).removeClass('active');
        }
        else {
          $(this).closest('.lui-collapse-item').addClass('opened');
          $(this).addClass('active');
        }
      });
    }
    collapse.attr('listener', 'true');
  });

  function setHeightFullSection() {
    const height = $(window).height();
    $('.error-page, .menu-full-overlay, .preloader .centrize').css({'height': height});
  }
}
